import React, { /*useContext*/} from 'react'
//import { GlobalDataContext } from "../../context/context";

function Loader() {
    //const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
    <div className='content-loader'>
      <div className="loader">
      </div>
    </div>
    </>
  )
}

export default Loader
