import React, { useContext } from "react";
//import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[2]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[3]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[4]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[5]}")` }}
        ></figure>
        <div className="container">
          <div className="z4 text-slider position-relative text-center d-flex flex-column align-items-center items-center mx-auto">
            <h4 className="text-white text-center pd-top-100">
            {rpdata?.dbSlogan?.[5].slogan}
            </h4>
            <div className="md:w-[10%] w-full h-0.5 bg-white my-2"></div>
            <h1 className="text-white text-center text-responsive">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <a className="btn btn-base text-white" href={`tel:+1${rpdata?.dbPrincipal?.phones[0].phone}`}>
              <i className="fa fa-phone-alt"></i>
             {rpdata?.dbPrincipal?.phones[0].phone}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
